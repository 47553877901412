import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Join us at `}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io"
      }}>{`SuperBot`}</a>{` on April 2nd in San Francisco – the premier conference for Chatbots, Voice Assistants, and AI featuring speakers from Google, Alexa, IBM, Intuit, Twilio, Slack, the BBC, Discovery, Assist, and many more. `}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io"
      }}>{`Register today`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      